<template>
  <section ref="intro" class="cv">
    <a href="CV.pdf" class="container cv--body">Show CV</a>
  </section>
</template>

<script>
export default {
  mounted() {
    console.log("hoi");
  },
};
</script>

<style lang="scss" scoped>
.cv {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;

  &--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
