<template>
  <nav class="navbar black is-fixed-top">
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': showNav }"
        @click="showNav = !showNav"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': showNav }">
      <div class="navbar-end">
        <div class="navbar-item">
          <a @click="handleNavigation('intro')">
            <span>Home</span>
          </a>
        </div>
        <div class="navbar-item">
          <a @click="handleNavigation('services')">
            <span>Services</span>
          </a>
        </div>
        <div class="navbar-item">
          <a @click="handleNavigation('partners-clients')">
            <span>Partners & Clients</span>
          </a>
        </div>
        <div class="navbar-item">
          <a @click="handleNavigation('portfolio')">
            <span>Recent work</span>
          </a>
        </div>
        <div class="navbar-item">
          <a @click="handleNavigation('contact')">
            <span>Contact</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data: () => {
    return {
      showNav: false,
    };
  },
  methods: {
    handleNavigation(selector) {
      const currentRoute = this.$route.name;

      if (currentRoute !== "Home") {
        this.$router.push({ name: "Home" });
        setTimeout(() => {
          this.scrollIntoViewWithOffset(selector);
        }, 300);
      } else {
        this.scrollIntoViewWithOffset(selector);
      }
    },

    scrollIntoViewWithOffset(selector) {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#" + selector).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          80,
      });

      this.showNav = !this.showNav;
    },
  },
};
</script>
