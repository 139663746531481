import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles.scss'
import Buefy from 'buefy'
import '@fortawesome/fontawesome-free/css/all.css'
import VueGtag from 'vue-gtag'
import ScrollAnimation from "./scrollanimation"


Vue.directive('scrollanimation', ScrollAnimation);
Vue.config.productionTip = false
Vue.use(Buefy, {
  defaultIconPack: 'fas'
  // defaultFieldLabelPosition: 'on-border'
})

Vue.use(VueGtag, {
  config: { id: 'G-KVDPMD78GX' }
}, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')