<template>
  <div id="app">
    <Navbar />
    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Merijn Slegers",
    // all titles will be injected into this template
    titleTemplate: "Merijn Slegers | %s ",
  },
};
</script>

<style lang="scss">
section {
  min-height: 700px;
}

.blink::after {
  content: "|";
  animation: blink 1s infinite step-start;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
</style>
