

// const DATA_COUNT = 7;
// const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};

const labels = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
const data = {
  labels: labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [77, 68, 270, 426, 371, 401, 589, 601, 423, 360, 168, 99],
      // backgroundColor: 'rgba(207, 198, 170, 1)',
      borderColor: 'rgba(207, 198, 170, 1)',
      borderWidth: 3
    },
    // {
    //   label: 'Dataset 2',
    //   data: Utils.numbers(NUMBER_CFG),
    //   backgroundColor: Utils.CHART_COLORS.blue,
    // },
    // {
    //   label: 'Dataset 3',
    //   data: Utils.numbers(NUMBER_CFG),
    //   backgroundColor: Utils.CHART_COLORS.green,
    // },
  ]
};


let delayed;


export const sportsData = {
      type: 'bar',
      data: data,
      options: {
        animation: {
            // loop: true,
          onComplete: () => {
            delayed = false;
          },
          delay: (context) => {
            let delay = 0;
            if (context.type === 'data' && context.mode === 'default' && !delayed) {
              delay = context.dataIndex * 400 + context.datasetIndex * 200;
            }
            return delay;
          },
        },
        plugins: {
            legend: {
              display: false
            }
          },
        scales: {
          x: {
            stacked: true,
            display: false,
          },
          y: {
            stacked: true,
            display: false,
          }
        }
      }
    };




export default sportsData;
