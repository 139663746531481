<template>
  <section ref="intro" class="intro">
    <div class="container intro--body">
      <v-typical
        :steps="['MERIJN SLEGERS']"
        :wrapper="'p'"
        class="title title-logo-text is-size-1 is-size-5-mobile"
      />
      <v-typical
        class="subtitle logo-text is-size-6 is-size-7-mobile has-text-link blink"
        :steps="introSteps"
        :loop="Infinity"
        :wrapper="'h3'"
      />
    </div>
  </section>
</template>

<script>
import VTypical from "vue-typical";

export default {
  components: {
    VTypical,
  },
  data() {
    return {
      introSteps: [
        "32 years old",
        1000,
        "raised in Brabant | based in Utrecht",
        1000,
        "freelance frontend developer",
        1000,
        "passion for web development",
        1000,
        "experienced in data visualization",
        1000,
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.intro {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  text-align: center;

  &--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.title-logo-text {
  letter-spacing: 10px;
}

.logo-text {
  letter-spacing: 3px;
}

.photo {
  max-width: 180px;
  border-radius: 25px;
  margin-bottom: 20px;
}
</style>
