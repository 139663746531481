<template>
  <div>
    <Intro id="intro" />
    <Services id="services" />
    <Partners id="partners-clients" />
    <Portfolio id="portfolio" />
    <Contact id="contact" />
  </div>
</template>

<script>
import Services from "@/components/Services.vue";
import Intro from "@/components/Intro.vue";
import Partners from "@/components/Partners.vue";
import Portfolio from "@/components/Portfolio.vue";
import Contact from "@/components/Contact.vue";

export default {
  components: {
    Intro,
    Services,
    Partners,
    Portfolio,
    Contact,
  },
  data() {
    return {
      showGraphs: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

