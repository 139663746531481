<template>
  <div>
    <canvas id="sports-chart" />
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import sportsData from "@/data/sports-data.js";

export default {
  name: "SportsbChart",
  data() {
    return {
      sportsData: sportsData,
      myChart: null,
    };
  },
  mounted() {
    const ctx = document.getElementById("sports-chart");
    this.myChart = new Chart(ctx, this.sportsData);

    setInterval(() => {
      this.myChart.reset();
      this.myChart.update();
    }, 6000);
  },
  methods: {
    // redraw() {
    //   const ctx = document.getElementById("sports-chart");
    //   this.myChart.destroy();
    //   this.myChart = new Chart(ctx, this.sportsData);
    // },
  },
};
</script>

