<template>
  <section ref="services" class="services container">
    <div class="section-title">SERVICES</div>
    <transition name="general-fade" mode="out-in" appear>
      <div class="columns services--body">
        <div class="column is-6">
          <div class="column-header">
            <div class="title has-text-link is-size-4">
              Front end web development
            </div>
            <p class="subtitle is-size-6">
              Development of web applications in multidisciplinary teams
            </p>
          </div>
          <div class="column-body">
            <CogsIcon />
          </div>
          <v-typical
            class="subtitle is-size-6 has-text-link blink"
            :steps="developmentSteps"
            :loop="Infinity"
            :wrapper="'h3'"
          />
        </div>

        <!-- <div class="column is-2">
          <div class="column-header">
            <div class="title has-text-link is-size-4">Advisory</div>
            <p class="subtitle is-size-6">
              Consulting in development, application ánd management of analytics
              solutions and teams
            </p>
          </div>
          <div class="column-body">
            <CrossIcon />
          </div>
          <v-typical
            class="subtitle logo-text is-size-6 has-text-link blink"
            :steps="advisorySteps"
            :loop="Infinity"
            :wrapper="'h3'"
          />
        </div> -->

        <div class="column is-6">
          <div class="column-header">
            <div class="title has-text-link is-size-4">Data visualization</div>
            <p class="subtitle is-size-6">
              From interactive dashboards to custom web visualizations
            </p>
          </div>
          <div class="column-body">
            <SportsChart />
          </div>
          <v-typical
            class="subtitle logo-text is-size-6 has-text-link blink"
            :steps="vizSteps"
            :loop="Infinity"
            :wrapper="'h3'"
          />
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import VTypical from "vue-typical";
import SportsChart from "@/components/icons/SportsChart";
import CogsIcon from "@/components/icons/CogsIcon.vue";
// import CrossIcon from "@/components/icons/CrossIcon.vue";

export default {
  components: {
    VTypical,
    SportsChart,
    CogsIcon,
    // CrossIcon,
  },
  data: () => {
    return {
      developmentSteps: [
        "Vue",
        1000,
        "Nuxt",
        1000,
        "React",
        1000,
        "Next",
        1000,
        "Typescript",
        1000,
        "SCSS",
        1000,
      ],
      advisorySteps: [
        "Consultancy",
        1000,
        "Performance management",
        1000,
        "Operational management",
        1000,
        "Continuous improvement",
        1000,
        "Visual analytics",
        1000,
      ],
      vizSteps: [
        "d3.js",
        1000,
        "c3.js",
        1000,
        "chart.js",
        1000,
        "ApexCharts",
        1000,
        "Tableau",
        1000,
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.services {
  margin: 100px auto;
  text-align: center;

  &--body {
    margin: 50px auto;
  }
}

.column {
  margin-bottom: 20px;
}

.column-header {
  height: 100px;
}

.column-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 220px;
  width: 100%;
  color: rgba(207, 198, 170, 1);
  margin: 15px auto;
}
</style>
