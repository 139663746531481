<template>
  <section ref="contact" class="contact container">
    <div class="section-title">CONTACT</div>
    <br />
    <div class="contact-box">
      <div class="contact-box--column">
        <p>
          You see possibilities to work together? <br />
          Reach out to me!
        </p>
        <ul class="has-text-link">
          <li>
            <a href="tel:+3-168-364-4226" class="has-text-link">
              <b-icon icon="phone" /> +31 6 83 64 42 26
            </a>
          </li>
          <li>
            <a href="mailto:info@merijnslegers.nl" class="has-text-link">
              <b-icon icon="envelope" /> info@merijnslegers.nl</a
            >
          </li>
        </ul>
      </div>
      <div class="contact-box--column">
        <img :src="require('@/assets/merijnslegers.jpg')" class="photo" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;

  .contact-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px $link solid;
    border-radius: 15px;
    flex-wrap: wrap;
    margin: 20px;
    max-width: 800px;

    &--column {
      display: flex;
      flex-direction: column;
      margin: 20px;
      gap: 30px;
      justify-self: center;
    }
  }
  .photo {
    max-width: 200px;
    width: 100%;
    border-radius: 15px;
  }
}
</style>
